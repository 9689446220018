
import styles from './page.css'
import { useState, useEffect } from 'react'
import axios from "axios";

export default function Home() {

  const [jTitle,setJTitle] = useState();
  const [resFile,setresFile] = useState();
  const [jd,setjd] = useState();
  async function resumepopup(id){
    if(id.target.localName=="dl")
      var did = id.target.childNodes[0];
    else
      var did = id.target;
    console.log(did);
    setJTitle(did.innerText);
    setjd(did.nextSibling.innerHTML)
    document.querySelector(".modalResr").classList.add("shown");
  }

  async function onFileChange(event  ) {
        // Update the state
        setresFile(event.target.files[0])
       
    };

  async function onFileUpload (){
        // Create an object of formData
        if(!resFile){
          alert("Select Resume file first.");
          return;
        }
        const formData = new FormData();
 
        // Update the formData object
        formData.append(
            "myFile",
            resFile,
            resFile.name
        );
 
        // Details of the uploaded file
 
        // Request made to the backend api
        // Send formData object
        var t1 = axios.post("/upload.php", formData).then(function (response) {
          alert(response.data);
          setresFile("");
          document.getElementById("resfile").value="";
        })
    };

  async function resumepopuphi(){
    document.querySelector(".modalResr").classList.remove("shown");
  }

  return (
    <>
      {/*<div className="leftCirc">
       <li >
         <div onClick={resumepopup}>Product Manager</div>
       </li>
       <li >
         <div onClick={resumepopup}>React Developer</div>
       </li>
     </div>
     <div className="rightCirc">
       <li >
         <div onClick={resumepopup}>Software PHP Developer</div>
       </li>
       <li >
         <div onClick={resumepopup}>React Developer</div>
       </li>
     </div>*/}
     <div className="jobBoxes">
       <dl onClick={resumepopup}>
         <div >React Developer</div>
         <div className="jd">
           We are looking for a great JavaScript developer who is proficient with React.js. Your primary focus will be on developing user interface components and implementing them following well-known React.js workflows (such as Flux or Redux). You will ensure that these components and the overall application are robust and easy to maintain. You will coordinate with the rest of the team working on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and quality product is important.
           <p>
           Responsibilities
           </p>
                Developing new user-facing features using React.js

                Building reusable components and front-end libraries for future use

                Translating designs and wireframes into high quality code

                Optimizing components for maximum performance across a vast array of web-capable devices and browsers

                Add other relevant responsibilities here
           <p>
           Skills
           </p>
                Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model

                Thorough understanding of React.js and its core principles

                Experience with popular React.js workflows (such as Flux or Redux)

                Familiarity with newer specifications of EcmaScript

                Experience with data structure libraries (e.g., Immutable.js)

                Knowledge of isomorphic React is a plus

                Familiarity with RESTful APIs

                Knowledge of modern authorization mechanisms, such as JSON Web Token

                Familiarity with modern front-end build pipelines and tools

                Experience with common front-end development tools such as Babel, Webpack, NPM, etc.

                Ability to understand business requirements and translate them into technical requirements

                A knack for benchmarking and optimization

                Familiarity with code versioning tools such as Git, SVN, and Mercurial

                Make sure to mention any other framework, libraries, or other technology relevant to your project

                List education level or certification you require

         </div>
       </dl>
       <dl onClick={resumepopup}>
         <div >Technical Lead</div>
         <div className="jd">
         We are searching for an innovative technical lead to join our company. As the technical lead, you will oversee the company’s technical team and all projects they undertake, analyze briefs, write progress reports, identify risks, and develop work schedules. You should be able to work with your team and inspire them to reach their goals.

To be successful as a technical lead, you should always be expanding your industry knowledge and be able to quickly identify problems. Outstanding technical leads are accountable, trustworthy, and able to build lasting relationships with their teams.
<p>Technical Lead Responsibilities:</p>

    Determining project requirements and developing work schedules for the team.
    Delegating tasks and achieving daily, weekly, and monthly goals.
    Liaising with team members, management, and clients to ensure projects are completed to standard.
    Identifying risks and forming contingency plans as soon as possible.
    Analyzing existing operations and scheduling training sessions and meetings to discuss improvements.
    Keeping up-to-date with industry trends and developments.
    Updating work schedules and performing troubleshooting as required.
    Motivating staff and creating a space where they can ask questions and voice their concerns.
    Being transparent with the team about challenges, failures, and successes.
    Writing progress reports and delivering presentations to the relevant stakeholders.

<p>Technical Lead Requirements:</p>

    Bachelor’s degree in computer science, engineering, or a related field.
    Relevant Management certification may be required.
    Experience in a similar role would be advantageous.
    Excellent technical, diagnostic, and troubleshooting skills.
    Strong leadership and organizational abilities.
    Willingness to build professional relationships with staff and clients.
    Excellent communication, motivational, and interpersonal skills.</div>
       </dl>
       <dl onClick={resumepopup}>
         <div >PHP Developer</div>
         <div className="jd">
           We are looking for a PHP Developer responsible for managing back-end services and the interchange of data between the server and the users. Your primary focus will be the development of all server-side logic, definition and maintenance of the central database, and ensuring high performance and responsiveness to requests from the front-end. You will also be responsible for integrating the front-end elements built by your co-workers into the application. Therefore, a basic understanding of front-end technologies is necessary as well.
<p>Responsibilities</p>

    Integration of user-facing elements developed by front-end developers
    Build efficient, testable, and reusable PHP modules
    Solve complex performance problems and architectural challenges
    Integration of data storage solutions may include databases, key-value stores, blob stores, etc.
    Add other responsibilities here that are relevant

<p>Skills And Qualifications</p>

    Strong knowledge of PHP web frameworks such as Laravel, Yii, etc depending on your technology stack
    Understanding the fully synchronous behavior of PHP
    Understanding of MVC design patterns
    Basic understanding of front-end technologies, such as JavaScript, HTML5, and CSS3
    Knowledge of object oriented PHP programming
    Understanding accessibility and security compliance Depending on the specific project
    Strong knowledge of the common PHP or web server exploits and their solutions
    Understanding fundamental design principles behind a scalable application
    User authentication and authorization between multiple systems, servers, and environments
    Integration of multiple data sources and databases into one system
    Familiarity with limitations of PHP as a platform and its workarounds
    Creating database schemas that represent and support business processes
    Familiarity with SQL/NoSQL databases and their declarative query languages
    Proficient understanding of code versioning tools, such as Git
    Make sure to mention other frameworks, libraries, or any other technology related to your development stack
    List education level or certification you require

         </div>
       </dl>
       <dl onClick={resumepopup}>
         <div >SQL Administrator</div>
         <div className="jd">
           The SQL Server DBA will be responsible for the implementation, configuration, maintenance, and performance of critical SQL Server RDBMS systems, to ensure the availability and consistent performance of our corporate applications. This is a “hands-on” position requiring solid technical skills, as well as excellent interpersonal and communication skills.

The successful candidate will be responsible for the development and sustainment of the SQL Server Warehouse, ensuring its operational readiness (security, health and performance), executing data loads, and performing data modeling in support of multiple development teams. The data warehouse supports an enterprise application suite of program management tools. Must be capable of working independently and collaboratively.
<p>Responsibilities</p>

    Manage SQL Server databases through multiple product lifecycle environments, from development to mission-critical production systems.
    Configure and maintain database servers and processes, including monitoring of system health and performance, to ensure high levels of performance, availability, and security.
    Apply data modeling techniques to ensure development and implementation support efforts meet integration and performance expectations
    Independently analyze, solve, and correct issues in real time, providing problem resolution end-to-end.
    Refine and automate regular processes, track issues, and document changes
    Assist developers with complex query tuning and schema refinement.
    Provide 24x7 support for critical production systems.
    Perform scheduled maintenance and support release deployment activities after hours.
    Share domain and technical expertise, providing technical mentorship and cross-training to other peers and team members.
    Add any other responsibilities here that are relevant
<p>
Skills and Qualifications</p>

    5+ years MS SQL Server Administration experience required
    Experience with Performance Tuning and Optimization (PTO), using native monitoring and troubleshooting tools
    Experience with backups, restores and recovery models
    Knowledge of High Availability (HA) and Disaster Recovery (DR) options for SQL Server
    Experience working with Windows server, including Active Directory
    Excellent written and verbal communication
    Flexible, team player, “get-it-done” personality
    Ability to organize and plan work independently
    Ability to work in a rapidly changing environment
    Ability to multi-task and context-switch effectively between different activities and teams
    MCTS, MCITP, and/or MVP certifications a plus
    Add any other unique skills and qualifications here that are needed for this position within your company
    List education level or certification you require

         </div>
       </dl>
     </div>
     <div className="modalRes modalResr">
       <button className="button" onClick={resumepopuphi}>🗙</button>
       <h1>{jTitle}</h1>
       <div className="raws">
         <div className="lbAr">
           <h2>Job Description</h2>
           <div
              dangerouslySetInnerHTML={{__html: jd}}
            />
         </div>
         <div className="rbAr">
           <p>Resume Upload</p>
           <div className="fileWrap">
              <input
                        type="file" id="resfile" placeholder="Resume Upload"
                        onChange={onFileChange}
                    />
                    <br/><br/>
                    <button onClick={onFileUpload}>
                        Upload!
                    </button>

           </div>
         </div>
       </div>
       
       
     </div>
    </>
  )
}
