
import styles from './page.css'
export default function Home() {

  return (
    <>
      <div className="herosection">
          <h1>
            Technologies
          </h1>
          <div className="tagline">
            Let's discover the commercial oppurtunities of the digital world together.
          </div>
        </div>
        <div className="sectionbox">
          <div className="serveBox">
            <div className="title">Frontend</div>
            <div className="ulli">
              <li>React</li>
              <li>Angular</li>
            </div>
          </div>
          <div className="serveBox">
            <div className="title">Backend</div>
            <div className="ulli">
              <li>Java</li>
              <li>.NET</li>
              <li>Laravel</li>
              <li>Node.JS</li>
              <li>VB .Net</li>
            </div>
          </div>
          <div className="serveBox">
            <div className="title">Mobile</div>
            <div className="ulli">
              <li>React Native</li>
              <li>Ionic</li>
              <li>Flutter</li>
              <li>Xamarin</li>
              <li>iOS</li>
              <li>Android</li>
            </div>
          </div>
          <div className="serveBox">
            <div className="title">Platform</div>
            <div className="ulli">
              <li>Wordpress</li>
              <li>Joomla</li>
              <li>Drupal</li>
              <li>Sharepoint</li>
            </div>
          </div>
          <div className="serveBox">
            <div className="title">Cloud</div>
            <div className="ulli">
              <li>AWS</li>
              <li>Azure</li>
            </div>
          </div>
        </div>
    </>
  )
}
