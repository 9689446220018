
import './page.css'
import Careerlist from './careerlist';
export default function Career() {

  return (
    <>
     <div className="herosection">
          <h1>
            Career Options
          </h1>
          <div class="tagline">
            Explore the oppurtunities with us.
          </div>
        </div>
        <div className="circleTop">
         <Careerlist/>
        </div>
    </>
  )
}
