
import styles from './page.css'
export default function Home() {

  return (
    <>
      <div className="herosection">
          <h1>
            Where Software Meets Magic
          </h1>
          <div class="tagline">
            Jai Sai Infotech is a specialized IT solutions provider committed to delivering cutting-edge services exclusively for clients. With a focus on precision, innovation, and efficiency, we strive to elevate the client satisfaction to new heights in the digital landscape.
          </div>
          {/*<button className="homebtn">Get Started</button>*/}
          <h3>We Believe In</h3>
        </div>
        <div className="section">
          <div className="divsec">
            <header>Client-Centric Approach</header>
            <div>
              Our firm is dedicated to the success of Client. Every solution we provide is crafted with your business goals in mind, ensuring maximum value and impact.
            </div>
          </div>
           <div className="divsec">
             <header>Proven Track Record</header>
             <div>
              Backed by a team of skilled professionals, we have a proven track record of delivering high-quality IT solutions that align with Client's firm's objectives.
            </div>
          </div>
           <div className="divsec">
             <header>Continuous Innovation</header>
             <div>
              In the ever-evolving IT landscape, we stay ahead of the curve by embracing the latest technologies and methodologies. This ensures that Client's satisfaction remains at the forefront of innovation.
            </div>
          </div>
        </div>
    </>
  )
}
