
import styles from './page.css'
export default function Home() {
  setTimeout(()=>{
    document.getElementById("mapi").contentWindow.scrollTo(455,455);
  },100)
  return (
    <>
      <div className="herosection">
          <h1>
            Contact Us
          </h1>
          <div class="tagline">
            
          </div>
        </div>
        <div className="sections">
          <div className="lBar">
            <iframe id="mapi" src="/map.png"/>
          </div>
          <div className="rBar">
            <p>
              <b>Address:</b> Office No.12 Second Floor, Aura Square,
              Near Panchvati Bus Stop, Refinery Road Gorwa, Vadodara-390016
              <br/>
              <b>Phone :</b> 9925103401
              <br/>
              <b>Email :</b> jayshree.reddy@jaisaiinfotech.com
            </p>
          </div>
        </div>
    </>
  )
}
