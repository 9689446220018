import './App.css';
import NavBar from './Nav.js';
import Home from './pages/home/index.js';
import Layout from './pages/layout.js';
import About from './pages/about/index.js';
import Career from './pages/career/index.js';
import Technology from './pages/technology/index.js';
import Contact from './pages/contact/index.js';

import { BrowserRouter, Route,Routes } from 'react-router-dom'
function App() {
  return (
    <div className="app_body">
          {/*<NavBar/>
          <Router>
            <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/about" element={<About/>}/>
              <Route exact path="/career" element={<Career/>}/>
              <Route exact path="/technology" element={<Technology/>}/>
              <Route exact path="/contact" element={<Contact/>}/>
            </Routes>
        </Router>*/}
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<Layout />} exact>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/career" element={<Career/>}/>
              <Route path="/technology" element={<Technology/>}/>
              <Route path="/contact" element={<Contact/>}/>
            </Route>
            </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
